.trusted-partners {
  position: relative;
  text-align: center;
  color: white;
  padding-left: 0;
  padding-right: 0;
  padding-top: 10px;
  padding-bottom: 10px;
  /* margin-top: 50px; */
  overflow-x: hidden; /* Prevent horizontal scrolling */
}

.transition-image {
  width: 100%; /* Ensure it fits within the container */
  height: auto;
}

.trusted-partners-content {
  /* position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%); */
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.trusted-partners-title {
  font-size: 24px;
  margin-bottom: 35px;
  color: rgb(23, 23, 23);
  letter-spacing: 2px;
}

.trusted-partners-logos {
  display: flex;
  justify-content: center;
  gap: 150px;
  flex-wrap: wrap; /* Allow logos to wrap to the next line on smaller screens */
  padding: 0 10px; /* Add padding to avoid overflow */
}

.partner-logo {
  max-width: 100px;
  height: auto;
}

@media (max-width: 700px) {
  .trusted-partners-title {
    font-size: 16px; /* Adjust title font size for mobile */
  }

  .trusted-partners-logos {
    gap: 50px; /* Reduce gap between logos on mobile */
  }

  .partner-logo {
    max-width: 80px; /* Reduce logo size for mobile */
  }
}
