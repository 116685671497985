.client-type-section {
    text-align: center;
    /* padding: 40px 20px; */
    margin-top: 80px;
    width: -webkit-fill-available;
    position: relative; /* Ensure the section can contain absolutely positioned elements */
  }
  
  .client-type-section h2 {
    margin-bottom: 20px;
    font-size: 24px;
  }
  
  .client-type-grid {
    display: grid;
    
    grid-template-columns: repeat(5, 1fr); /* 5 cards per row */
    gap: 20px;
    justify-content: center;
    margin-bottom: 0px; /* Add space for the background image */
    position: absolute;
    bottom: 0%;
    left: 50%;
    transform: translateX(-50%);
    z-index: 1;
  }
  
  .background-image-container {
    position: relative;
    height: 290px; /* Adjust height as needed */
    width: 100%;
    overflow: hidden;
    /* margin-top: -100px; Negative margin to pull it up */
  }
  
  .background-image {
    width: 100%;
    height: 100%;
    object-fit: cover;
    overflow: hidden;
  }
  
  .overlay-grid {
    position: absolute;
    bottom: 1%; /* Adjust to position the cards halfway on the image */
    left: 50%;
    transform: translateX(-50%);
    display: grid;
    grid-template-columns: repeat(5, 1fr);
    gap: 20px;
    justify-content: center;
  }
  

  @media (max-width: 700px) {

    .client-type-section {
      margin-top: 20px;
    }
    .client-type-section h2 {
      font-size: 16px;
      margin-bottom: 5px;
    }

    .client-type-grid {
      position: relative;
      grid-template-columns: repeat(2, 1fr);
      margin: 20px;
      margin: 0px 35px;
      gap: 0px;
      column-gap: 95px;
      transform: translateX(0%);
      left: 0;

      
    }

    .overlay-grid {
      grid-template-columns: repeat(2,1fr);
      margin: 0px 35px;
      gap: 0px;
      column-gap: 95px;
      transform: translateX(0%);
      left: 0;
      bottom: 29%;
    }
    .background-image-container {
      width: auto;
    }

    .background-image {
      width: 100vw;
      height: 100%;
    }
  }