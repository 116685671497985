.reviews-section {
    text-align: center;
    padding: 50px 50px;
    margin: 0px 70px;
  }
  
  .reviews-section h2 {
    font-size: 2rem;
    margin-bottom: 40px;
  }
  
  .reviews-grid {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    gap: 30px;
    margin-top: 30px;
  }
  
  .review-image {
    max-width: 500px;
    height: auto;
    width: 100%;
    border-radius: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  }

  @media (max-width: 700px) {
    .reviews-section {
      padding: 10px;
      margin: 10px;    }
  }