.top-button-div {
  margin-top: 40px;
  padding-bottom: 25px;
}

@keyframes swoon {
  0% {
    background-position-x: 0;
  }

  100% {
    background-position-x: 99%;
  }
}

.top-button-a {
  color: rgb(35, 35, 34);
  background: rgb(240, 248, 112);
  font-size: 20px;
  background-image: linear-gradient(
    90deg,
    #6fffde 0%,
    #ffe245 20%,
    #ff5057 40%,
    #ffe245 60%,
    #6fffde 80%,
    #ffe245 100%
  ) !important;
  transition: background 0.2s, padding 0.2s;
  padding: 1em 2em;
  background-size: 600% !important;
  animation: swoon 4s infinite linear;
  background-color: transparent !important;
  padding-left: 60px !important;
  padding-right: 60px !important;

  font-family: Silka Regular New;
  border-radius: 60px;
  padding-top: 15px !important;
  padding-bottom: 15px !important;
  margin-right: auto;
  margin-left: auto;
  display: inline-block;
  text-align: center !important;
  text-decoration: none !important;
  letter-spacing: 2px;
  box-sizing: border-box;
  line-height: 1.42857143;
}

.animation {
  -webkit-transition: all 0.2s ease-in-out !important;
  box-shadow: 0px 5px 30px 6px rgba(240, 248, 112, 0.3);
  filter: drop-shadow(0px 8px 30px rgba(0, 0, 0, 0.25)) !important;
}

@media (max-width: 700px) {
  .top-button-a {
    font-size: 16px;
    padding-left: 40px !important;
    padding-right: 40px !important;
    padding-top: 10px !important;
    padding-bottom: 10px !important;
  }
}

@media (max-width: 480px) {
  .top-button-a {
    font-size: 14px;
    padding-left: 30px !important;
    padding-right: 30px !important;
    padding-top: 8px !important;
    padding-bottom: 8px !important;
  }
}