.team-section {
    text-align: center;

    padding: 20px; /* Adds space inside the container */
    margin: 0 50px; /* Adds space outside the container */
  }
  
  .team-section h2 {
    margin-bottom: 20px;
    font-size: 24px;
  }
  
  .team-grid {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    gap: 20px;
  }
  
  @media (max-width: 700px){
    .team-section {
      margin: 0 10px;
      padding: 0;
    }
  }