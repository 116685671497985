.local-video-container {
  display: flex;
  justify-content: center;
  margin-top: 30px;
  margin-bottom: 10px;
  background-color: transparent; /* Optional background color */
  width: 400px;
  height: 250px;
  border: 10px solid black;
  border-radius: 20px;
}


.video {
  width: 100%;
  max-width: 500px;
  height: auto;
}

@media (max-width: 768px) {
  .video {
    width: 100%;
  }
}
