.about-headline-u {
  background: transparent;
  color: #eca24e;
  text-decoration: none;
  font-size: 24px;
  text-align: left;
}
.about-main-div {
  display: flex;
  flex-direction: row;
}
.about-headline-h1 {
  font-size: 40px;
  color: rgb(255, 255, 255);
  position: relative;
  line-height: 1.3em;
  font-weight: 500;
  font-family: Silka Bold New !important;
}

.about-headline-div {
  margin-top: 0px;
  margin-left: 100px;
  width: 50%;
  outline: none;
  cursor: pointer;
  opacity: 1;
  position: relative;
}

.about-description {
  font-size: 18px;
  text-align: left;
  color: white;
}

.about-picture {
  padding-left: 100px;
  transform: translateY(55px);
}

.about-picture img {
  padding-left: 0;
  background: transparent;
  border: none;
  width: 100%; /* Adjust as necessary */
  height: auto; /* Maintain aspect ratio */
  max-width: 400px; /* Restrict maximum width */
  max-height: 500px; /* Restrict maximum height */
}

@media (max-width: 700px) {
  .about-headline-u {
    font-size: 14px;
  }

  .about-headline-h1 {
    font-size: 16px;
  }

  .about-headline-div {
    display: flex;
    flex-direction: column;
    position: relative;
    justify-content: center;
    margin: 0 10px; /* Adjust the margins to prevent overflow */
    padding: 0 20px;
    width: 100%; /* Set width to 100% */
    box-sizing: border-box; /* Ensure padding and border are included in the element's total width */
    overflow-x: hidden;
  }

  .about-description {
    font-size: 14px;
  }

  .about-picture {
    padding-left: 0; /* Remove left padding for mobile view */
    /* transform: translateY(0); Reset the transform property for mobile view */
    text-align: center; /* Center the picture in mobile view */
  }

  .about-picture img {
    /* Maintain aspect ratio */
    max-width: 250px;
    max-height: 350px;
  }
  .about-main-div {
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
}
