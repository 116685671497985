.headline-u {
  background: transparent;
  color: #eca24e;
  text-decoration: none;
}

.center {
  display: flex;
  justify-content: center;
  align-items: center;
}

.headline-h1 {
  text-align: center;
  font-size: 40px;
  color: rgb(255, 255, 255);
  position: relative;
  line-height: 1.3em;
  font-weight: 500;
  font-family: Silka Bold New !important;
}

.headline-div {
  margin-top: 0px;
  outline: none;
  cursor: pointer;
  opacity: 1;
  position: relative;
}

@media (max-width: 700px) {
  .headline-h1 {
    font-size: 26px;
  }
}

