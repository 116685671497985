.transition-section-2 {
  color: #fff;
  /* text-align: center; */
  
  position: relative;
  width: 100%;
  background-color: rgb(35, 35, 34);
}

/* .transition-container {
  background-image: url(./salman-transition.png);
} */

.transition-content {
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  font-family: Silka Bold New !important;
  padding-left: 20px;
  padding-right: 20px;
  padding-top: 50px;
  padding-bottom: 0px;
}

.transition-header {
  text-align: left;
  max-width: 600px;
  margin-right: 20px;
  line-height: 1.42;
}

.transition-header h2 {
  font-size: 40px;
  margin-bottom: 20px;
  color: rgb(236, 162, 78);
  font-weight: bolder;
}

.transition-header p {
  font-size: 24px;
  margin-bottom: 30px;
  color: white;
}

.side-image {
  max-width: 400px;
  border-radius: 10px;
}

.transition-benefits {
  background-image: url("https://www.conversionstudio.co/hosted/images/c8/111276c3954f0da8f6b0a6f0145749/bottom-bg.svg");
  display: flex;
  justify-content: center;
  padding: 0px 0px;
  flex-wrap: wrap;
  background-size: cover;
  /* background-clip: border-box; */
  /* margin-top: -180px; */
  /* transform: translateY(220px); */
  
  font-family: Silka Bold New !important;
}

.benefit-item {
  max-width: 200px;
  text-align: center;
  margin: 20px;
  color: rgb(250, 247, 243);
  background: linear-gradient(180deg, #5B5B57 0%, #2B2B25 100%);
  padding: 25px;
  border-radius: 10px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
  font-size: 20px;
  font-weight: bold;
  z-index: 1;
}

.benefit-item img {
  width: 50px;
  height: 50px;
  margin-bottom: 10px;
}

/* Media Queries for Mobile Responsiveness */
@media (max-width: 700px) {
  .transition-benefits {
    margin-top: 25px;
  }
  .transition-content {
    flex-direction: column;
    text-align: center;
  }

  .transition-header {
    margin-right: 0;
    text-align: center;
  }

  .transition-header h2 {
    font-size: 16px;
  }

  .transition-header p {
    font-size: 12px;
  }

  .side-image {
    max-width: 100%;
    margin-top: 20px;
  }

  .transition-benefits {
    /* position: absolute;
    bottom: 0%; */
    /* transform: translateY(0); */
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
  }

  .benefit-item {
    max-width: 35%;
    margin: 10px;
    font-size: 14px;
    padding: 15px;
  }

  .benefit-item img {
    width: 30px;
    height: 30px;
  }
}
