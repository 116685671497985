.case-studies-section {
    text-align: center;
    padding: 50px 50px;
    margin: 0px 70px;
  }
  
  .case-studies-section h2 {
    font-size: 2rem;
    margin-bottom: 40px;
  }
  

  @media (max-width: 700px) {
    .case-studies-section {
      padding: 10px;
      margin: 10px;
    }

    .case-studies-section h2 {
      font-size: 1.625rem;
      margin-bottom: 20px;
    }
  }