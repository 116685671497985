.Top {
  background-color: rgb(250, 247, 243);
  position: relative;
}
.Top-div {
  background-color: rgb(250, 247, 243);
  position: relative;
}
.center {
  display: flex;
  justify-content: center;
  align-items: center;
}

.top-transition {
  width: -webkit-fill-available;
  /* margin-top: -150px; */
  /* padding-top: 100px;
  padding-bottom: 100px;
  background-image: url(https://www.conversionstudio.co/hosted/images/a4/18ebe95c594208af432167a46e4a97/hero-bg.svg); */
}

.transition-section {
  display: flex;
}

.transition-container {
  position: relative;
  display: flex;
  height: 150px;
}
.video-div {
  position: absolute;
  top: 30%;
  /* background-color: rgb(35, 35, 34); */
}

