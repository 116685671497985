.team-card {
    border-radius: 8px;
    overflow: hidden;
    width: 250px; /* Adjust the width as needed */
    /* height: 350px; Adjust the height as needed */
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 20px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    transition: transform 0.3s ease;
    margin: 0 15px;
  }
  
  .team-card:hover {
    transform: translateY(-10px);
  }
  
  .team-card-image {
    width: 200px; /* Adjust size to fit within the card */
    height: 200px; /* Adjust size to fit within the card */
    border-radius: 50%;
    margin-bottom: 20px;
  }
  
  .team-card-content {
    text-align: center;
  }
  
  .team-card-name {
    font-size: 24px;
    font-weight: bold;
    margin-bottom: 10px;
    line-height: 1.3em;
  }
  
  .team-card-role {
    font-family: Silka Bold New !important;
    color: rgb(250, 247, 243);
    font-size: 20px;
    line-height: 1.5;
    margin: 5px 0 0;
  }
  
  @media (max-width: 700px) {
    .team-card {
      width: 130px;
      margin: 0;

    }

    .team-card-name {
      font-size: 16px;
      margin-bottom: 5px;
    }

    .team-card-role {
      font-size: 12px;
      line-height: 1.3;
    }

    .team-card-image {
        width: 80px;
        height: 80px;
        margin-bottom: 5px;
    }


  }