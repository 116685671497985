.case-study {
    display: flex;
    align-items: center;
    margin: 40px 0;
  }
  
  .case-study.reverse {
    flex-direction: row-reverse;
  }
  
  .case-study-image {
    max-width: 45%;
    height: auto;
    margin-right: 20px;
  }
  
  .case-study.reverse .case-study-image {
    margin-right: 0;
    margin-left: 20px;
  }
  
  .case-study-content {
    max-width: 55%;
  }
  
  .case-study-content h3 {
    font-size: 24px;
    font-weight: normal;
    margin-bottom: 10px;
    font-family: Silka Bold New !important;
    color: #eca24e;
  }
  
  .case-study-content p {
    font-size: 20px;
    line-height: 1.6;
    font-family: Silka Bold New !important;
  }
  

  @media (max-width: 700px) {
    .case-study-content h3 {
      font-size: 16px;
    }

    .case-study-content p {
      font-size: 12px;
      line-height: 1.3;
    }
  }