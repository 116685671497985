.testimonial-card {
  background-color: transparent;
  text-align: center;
  margin: 20px 80px;
  width: 100%;
  max-width: 200px;
  height: auto;
  display: flex;
  flex-direction: column;

  /* align-items: center; */
}

.player-wrapper {
  width: 100%;
  height: 0;
  padding-top: 177.77%; /* Maintain 9:16 aspect ratio for portrait videos */
  position: relative;
  
}

.react-player {
  position: absolute;
  top: 0;
  left: 0;
  
}

.quote, .name, .title {
  font-style: italic;
  text-align: left !important; 
  font-size: 16px;
  
  font-family: Silka Regular New;
}



.name {
  color: #eca24e;
  font-weight: bold;
  font-style: normal;
  margin: 6px 0;
}

.title {
  color: rgb(89, 89, 89);
  font-style: normal;
  margin: 6px 0;
}


@media (max-width: 700px) {
  .testimonial-card {
    max-width: 100px;
    margin: 0px 20px;
  }
  

  .quote, .name, .title {
    font-size: 12px;
    margin: 8px 0;
  }
}
