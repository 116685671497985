.App {
  font-family: sans-serif;
  text-align: center;
  font-size: 0;
  position: relative;
  width: 100vw;
  margin: 0;
  overflow-x: hidden;
  background-color: rgb(250, 247, 243);
}

.Second-section {
  padding-top: 0px;
  padding-bottom: 0px;
  margin: 0px auto;
  outline: none;
  display: flex;
  align-items: center;
  justify-content: center;
  /* padding-left: 150px !important;
  padding-right: 150px !important; */
}

.center {
  display: flex;
  justify-content: center;
  align-items: center;
}



.section {
  padding-top: 350px;
  padding-bottom: 20px;
  background-color: rgb(35, 35, 34);
  outline: none;
  overflow: visible;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-repeat: no-repeat !important;
}



.transition-section-div {
  /* background-image: url("https://www.conversionstudio.co/hosted/images/c8/111276c3954f0da8f6b0a6f0145749/bottom-bg.svg"); */
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  flex-direction: column;
  
}

.transition-section-div-2 {
  background-image: url("https://www.conversionstudio.co/hosted/images/c8/111276c3954f0da8f6b0a6f0145749/bottom-bg.svg");
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  margin-bottom: -10px;

}

.sub-headline-div {
  display: flex;
  text-align: center;
  font-size: 24px;
  color: rgb(250, 247, 243);
  position: relative;
  line-height: 1.6em;
  letter-spacing: 2px;
  font-weight: 500;
  font-family: Silka Bold New !important;
  padding-left: 200px ;
  padding-right: 200px;
}

.Client-Section {
  position: relative;
  background-color: rgb(250, 247, 243);
}

.Choose-Section {
  position: relative;
  margin-top: -10px;
  background-color: rgb(35, 35, 34);
}

.meet {
  font-weight: bold;
  font-family: Silka Bold New !important;
  color: white;
  line-height: 1.3em;
  font-size: 50px;
  padding-top: 40px;
  margin-bottom: 20px;
  margin-left: 20px;
  margin-right: 20px;
  text-align: center;
}

.about {
  position: relative;
  padding-top: 50px;
  background: linear-gradient(180deg, #232322 0%, #8b601d 100%) !important;
  padding-bottom: 50px;
  display: flex;
  flex-direction: column;
}

.footer-section {
  padding-top: 20px;
  padding-bottom: 20px;
  background-color: rgb(23, 23, 23);
  outline: none;
  position: relative;
  border-top: 2px solid rgb(228, 199, 155);
}

@media (max-width: 700px) {
  .section {
    padding-top: 50px;
    padding-bottom: 10px;
  }
  .sub-headline-div {
    font-size: 16px;
    line-height: 1.3em;
    letter-spacing: 1px;
    padding-left: 20px;
    padding-right: 20px;
  }
}


