.card {
  background-color: rgb(250, 247, 243);
  padding: 20px 40px;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  transition: transform 0.2s;
  flex: 1 1 45%; /* Adjusts the card size based on available space */
  margin: 10px; /* Margin to space out the cards */
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 300px; /* Ensures all cards have a minimum height */
  max-width: 400px; /* Ensures all cards have a maximum width */
}

.card:hover {
  transform: translateY(-10px);
}

.card-icon {
  font-size: 50px;
  margin-bottom: 20px;
}

.card-title {
  font-size: 40px;
  font-weight: bold;
  margin-bottom: 10px;
  color: rgb(236, 162, 78);
  line-height: 1.3em;
  font-family: Silka Bold New !important;
}

.card-description {
  text-align: left;
  font-family: Silka Bold New !important;
  font-size: 20px;
  line-height: 1.5;
  flex-grow: 1; /* Ensures the description area takes up remaining space */
  color: rgb(35, 35, 34);
}

.icon {
  margin-right: 8px;
}

.card li {
  display: flex;
  align-items: center;
  margin-bottom: 8px;
  text-align: left;
  font-family: Silka Bold New !important;
  font-size: 20px;
  line-height: 1.5;
  color: rgb(35, 35, 34);
}

.card ul {
  list-style-type: none;
  padding: 0;
}

/* Media query for mobile responsiveness */
@media (max-width: 768px) {
  .card {
    flex: 1 1 100%;
    /* height: auto; Adjust height for smaller screens */
    margin: 10px;
    padding: 10px;
    padding-top: 5px;
    width: 150px;
    height: 220px;
    max-height: 250px;
  }
  

  .card-title {
    font-size: 16px; /* Adjust font size for smaller screens */
  }

  .card-description {
    font-size: 12px; /* Adjust font size for smaller screens */
  }

  .card-icon {
    font-size: 40px; /* Adjust icon size for smaller screens */
  }

  .card li {
    font-size: 10px; /* Adjust list item font size for smaller screens */
  }
}
