.footer {
    background-color: #1c1c1c;
    color: #ffffff;
    padding: 20px 0;
    text-align: center;
    font-family: Arial, sans-serif;
  }
  
  .footer-content {
    max-width: 800px;
    margin: 0 auto;
    padding: 0 20px;
  }
  
  .footer-logo {
    width: 150px;
    margin-bottom: 20px;
  }
  
  .footer a {
    color: #ffffff;
    text-decoration: underline;
  }
  
  .footer p {
    margin: 5px 0;
  }
  
  .footer p:nth-child(3) {
    margin: 10px 0;
  }
  
  .footer p:nth-child(4) {
    color: #f0a500;
  }
  
  .footer p:nth-child(5) {
    font-size: 12px;
    margin: 10px 0;
  }
  
  .footer p:nth-child(6) {
    color: #f0a500;
  }
  