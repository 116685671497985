.center {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}
.logo-div {
  padding-top: 10px;
}

.logo {
  width: 150px;
}

.headline {
  margin-top: 10px;
  text-align: center;
}

.headline-simple-text {
  font-weight: normal;
  font-size: 50px;
  font-family: Silka Bold New !important;
  line-height: 1.3em;
}

.headline-highlight-text {
  font-weight: normal;
  font-size: 50px;
  font-family: Silka Bold New !important;
  line-height: 1.3em;
  text-align: center;
  /* margin-left: 150px;
  margin-right: 150px; */
  text-decoration: none;
  background: linear-gradient(to bottom, transparent 50%, #f0f870 50%);
}

.sub-headline {
  margin-top: 20px;
  text-align: center;
  font-size: 20px;
  color: rgb(89, 89, 89);
  font-family: Silka Regular New;
  line-height: 1.3em;
}

@media (max-width: 700px) {
  .logo {
    width: 100px; /* Adjust the logo size for mobile */
  }

  .headline-simple-text,
  .headline-highlight-text {
    font-size: 26px; /* Reduce the font size for smaller screens */
  }

  .sub-headline {
    font-size: 16px; /* Adjust sub-headline font size */
    padding: 0px 10px;
  }
}
