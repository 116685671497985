.client-type-card {
  background-color: #fff;
  border: 1px solid #e0e0e0;
  border-radius: 8px;
  width: 180px; /* Fixed width */
  height: 150px; /* Fixed height */
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  transition: transform 0.3s ease;
  margin: 10px;
}

.client-type-card:hover {
  transform: translateY(-5px);
}

.client-type-icon {
  max-width: 60px;
  margin-bottom: 10px;
}

.client-type-title {
  font-size: 16px;
  font-weight: bold;
  color: #333;
}


@media (max-width: 700px) {
  .client-type-card {
    width: 120px;
    height: 90px;
    margin: 5px;
  }

  .client-type-icon {
    max-width: 30px;
    margin-bottom: 5px;

  }

  .client-type-title {
    font-size: 12px;
  }
}
